import { Dispatch } from 'redux'
import { checkCouponCodesValidity, checkCouponValidity } from '../apis/couponApi'
import { addDogs } from '../apis/dogApi'
import { Action, ActionType, DogType } from '../types/dogType'

export const createDogs = (dogInfo: DogType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ADD_DOGS_PENDING
    })
    try {
      const success = await addDogs(dogInfo)
      for (let i = dogInfo.dogs.length; i < 5; i++) {
        dogInfo.dogs.push({
          name: '',
          weight: 0,
          dob: '',
          exercise: '',
          physique: '',
          neutered: '',
          gender: '',
          breed: '',
          ageStage: '',
          medicalConditionOrAllergies: null,
          unknownBreed: null
        })
      }
      dogInfo.growthbook = success.growthbook
      dogInfo.customer.marketingCookieConsent = window?.Cookiebot?.consent?.marketing
      dispatch({
        type: ActionType.ADD_DOGS_SUCCESS,
        payload: {
          dogInfo,
          summary: success.dogs,
          coupon: success.defaultCoupon,
          couponCodes: success.defaultCoupons,
          isValidCoupon: success.defaultCoupon.code ? true : false,
          pack: success.defaultPackSize,
          packSizes: success.packSizes,
          defaultPack: success.defaultPackSize.default,
          planLevel: success.defaultPackSize.planLevel,
          packNew: success.planLevels
        }
      })
    } catch (err) {
      dispatch({
        type: ActionType.ADD_DOGS_FAIL,
        payload: { errorMessage: err }
      })
    }
  }
}

export const updateCurrCouponCode = (code: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_CURRCOUPON_CODE,
      payload: code
    })
  }
}

export const updateCouponCodeValidity = (isValid: boolean | string, code?: string) => {
  return async (dispatch: Dispatch<Action>) => {
    if (typeof isValid === 'boolean') {
      dispatch({
        type: ActionType.UPDATE_COUPON_CODE_VALIDITY_SUCCESS,
        payload: { isValid },
        code
      })
    } else {
      dispatch({
        type: ActionType.UPDATE_COUPON_CODE_VALIDITY_PENDING
      })
      try {
        const res = await checkCouponValidity(code as string)
        dispatch({
          type: ActionType.UPDATE_COUPON_CODE_VALIDITY_SUCCESS,
          payload: {
            isValid: true,
            lastValidCode: res.couponCode,
            lastValidCodes: res.couponCodes
          }
        })
      } catch (_err) {
        dispatch({
          type: ActionType.UPDATE_COUPON_CODE_VALIDITY_SUCCESS,
          payload: { isValid: false }
        })
      }
    }
  }
}

export const updateCustomerEmail = (email: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_CUSTOMER_EMAIL,
      payload: email
    })
  }
}

export const updateChangedStatus = (status: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_CHANGE_STATUS,
      payload: status
    })
  }
}

export const resetLoadingStatus = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_LOADING_STATUS
    })
  }
}

export const resetErrorMessageForDogs = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET_ERROR_MESSAGE
    })
  }
}

export const resetDogs = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET
    })
  }
}
