import { Amplify, Auth, Cache } from 'aws-amplify'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
//@ts-ignore
import awsconfig from '../aws-exports'

import { removeCognitoId } from 'components/util/UserId'
import { resetDogs as resetDogsAccount } from 'state/actions/account/accountDogAction'
import { resetPlansAndOrders } from 'state/actions/account/accountPlanAction'
import { resetFlowDogs } from 'state/actions/account/flow/dogAction'
import { resetFlowPlan } from 'state/actions/account/flow/planAction'
import { resetFlowShipping } from 'state/actions/account/flow/shippingAction'
import { resetAccountSummary } from 'state/actions/accountSummary'
import { resetDogs } from 'state/actions/dogAction'
import { resetHashes } from 'state/actions/hashAction'
import { resetKey } from 'state/actions/keyAction'
import { resetParent } from 'state/actions/parentAction'
import { resetPlan } from 'state/actions/planAction'
import { resetShipping } from 'state/actions/shippingAction'

Amplify.configure(awsconfig)

export default function reset() {
  const dispatch = useDispatch()

  useEffect(() => {
    const signOut = async () => {
      await Auth.signOut()
      removeCognitoId()
    }

    Cache.removeItem('coupon')

    dispatch(resetAccountSummary())
    dispatch(resetDogs())
    dispatch(resetDogsAccount())
    dispatch(resetFlowDogs())
    dispatch(resetFlowPlan())
    dispatch(resetFlowShipping())
    dispatch(resetHashes())
    dispatch(resetKey())
    dispatch(resetParent())
    dispatch(resetPlan())
    dispatch(resetPlansAndOrders())
    dispatch(resetShipping())
    signOut()
    // window.location.href = `/signup/dog-details`
  }, [])

  return (
    <div>
      <meta http-equiv="refresh" content="1;url=/signup/dog-details" />
      <p>Please wait whilst we redirect you...</p>
    </div>
  )
}
