import { Auth, Cache } from 'aws-amplify'

export const getVisitorId = async () => {
  let visitorId = Cache.getItem('visitorId')

  // check if visitorId exists in cache before setting
  if (!visitorId) {
    visitorId = uuidv4()
    Cache.setItem('visitorId', visitorId)
  }

  // Add visitorId to datalayer
  if (
    typeof window !== 'undefined' &&
    window.dataLayer &&
    window.google_tag_manager &&
    process.env.GATSBY_GTM_ID &&
    visitorId
  ) {
    const usernameField = window.google_tag_manager[process.env.GATSBY_GTM_ID].dataLayer.get('visitorId')
    if (!usernameField) {
      window.dataLayer.push({
        event: 'setVisitorId',
        visitorId
      })
    }
  }

  return visitorId
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
// const visitorId = uuidv4()

// Set visitorId to cache
// Cache.setItem('visitorId', visitorId)

// Add visitorId to datalayer
//   if (
//     typeof window.dataLayer !== 'undefined' &&
//     typeof window.google_tag_manager !== 'undefined' &&
//     process.env.GATSBY_GTM_ID &&
//     visitorId
//   ) {
//     const usernameField = google_tag_manager[process.env.GATSBY_GTM_ID].dataLayer.get('visitorId')
//     if (!usernameField) {
//       window.dataLayer.push({
//         event: 'setVisitorId',
//         visitorId
//       })
//     }
//   }
//   return visitorId
// }

export const getCognitoId = async () => {
  try {
    const { username } = await Auth.currentAuthenticatedUser()
    if (
      typeof window.dataLayer !== 'undefined' &&
      typeof window.google_tag_manager !== 'undefined' &&
      process.env.GATSBY_GTM_ID &&
      username
    ) {
      const usernameField = google_tag_manager[process.env.GATSBY_GTM_ID].dataLayer.get('userId')
      if (!usernameField) {
        window.dataLayer.push({
          event: 'setUserId',
          userId: username
        })
      }
    }
    return username
  } catch (_e) {
    if (
      typeof window.dataLayer !== 'undefined' &&
      typeof window.google_tag_manager !== 'undefined' &&
      process.env.GATSBY_GTM_ID
    ) {
      const usernameField = google_tag_manager[process.env.GATSBY_GTM_ID].dataLayer.get('userId')
      if (usernameField) {
        window.dataLayer.push({
          event: 'setUserId',
          userId: ''
        })
      }
    }
    return ''
  }
}

export const removeCognitoId = async () => {
  if (
    typeof window.dataLayer !== 'undefined' &&
    typeof window.google_tag_manager !== 'undefined' &&
    process.env.GATSBY_GTM_ID
  ) {
    const usernameField = google_tag_manager[process.env.GATSBY_GTM_ID].dataLayer.get('userId')
    if (usernameField) {
      window.dataLayer.push({
        event: 'setUserId',
        userId: ''
      })
    }
  }
}
