import { DogType } from '../types/dogType'
import Api from './api'

export function addDogs(dogInfo: DogType) {
  return new Promise<any>((resolve, reject) => {
    Api.post('wwwREST', '/v1/public/signup/dogs', { body: dogInfo })
      .then(res => resolve(res))
      .catch(err => reject(err.response.data))
  })
}
